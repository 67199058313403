<template>
  <MainFarmCard
    :imageSrc="userImage"
    :title="request.fullName"
    :fields="requestFields"
    :address="request.address"
  >
    <!-- Custom Slot for Status Field -->
    <template #status>
      <span :class="getStatusClass(request.status)">
        {{ getStatusLabel(request.status) }}
      </span>
    </template>

    <!-- Operations for RequestCard -->
    <template #actions>
      <CDropdown
        placement="bottom-end"
        direction="dropup"
        v-if="request.status == 1"
      >
        <template #toggler-content>عملیات</template>
        <CDropdownItem class="y-center-g-5" @click="openAdviceModal(request)">
          <i class="fa fa-graduation-cap"></i>
          <span>توصیه ها</span>
        </CDropdownItem>
        <CDropdownItem class="y-center-g-5" @click="openFarmModal(request)">
          <i class="fa fa-map-marker"></i>
          <span>زمین ها</span>
        </CDropdownItem>
      </CDropdown>
      <CButton
        v-if="request.status == 1"
        class="requestItemBtn flex-grow-1"
        color="danger"
        @click="endOfCooperation(request)"
        style="padding: 3px 32px"
      >
        اتمام همکاری
      </CButton>
      <CButton
        v-if="request.status == 0"
        class="requestItemBtn flex-grow-1"
        color="danger"
        @click="endOfCooperation(request)"
        style="padding: 5px 12px"
      >
        لغو درخواست
      </CButton>
    </template>
  </MainFarmCard>
</template>

<script>
import MainFarmCard from "../../../components/MainFarmCard.vue";

export default {
  components: {
    MainFarmCard,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userImage: "https://picsum.photos/200",
    };
  },
  computed: {
    requestFields() {
      return [
        { label: "شماره همراه", value: this.request.phone },
        { label: "نوع کاربری", value: this.request.userType },
        { label: "تاریخ درخواست", value: this.request.rquestDateTime },
        {
          label: "وضعیت",
          value: this.getStatusLabel(this.request.status),
          slot: "status",
        }, // Use 'status' slot for status field
      ];
    },
  },
  methods: {
    openAdviceModal(request) {
      this.$emit("openAdvice", request);
    },
    openFarmModal(request) {
      this.$emit("openFarm", request);
    },
    endOfCooperation(request) {
      this.$emit("openFarm", request);
    },
    getStatusLabel(status) {
      const statusOptions = [
        { value: 0, label: "در حال بررسی" },
        { value: 1, label: "تایید شده" },
        { value: 2, label: "رد شده" },
      ];
      const statusOption = statusOptions.find(
        (option) => option.value === status
      );
      return statusOption ? statusOption.label : "";
    },
    getStatusClass(status) {
      return status === 1
        ? "text-success"
        : status === 2
        ? "text-danger"
        : "text-warning";
    },
  },
};
</script>

<style scoped>
/* Custom styles for RequestCard */
</style>
