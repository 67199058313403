<!-- SpecialistCard.vue -->
<template>
  <div class="main-card h-100 gap-20 d-flex flex-column">
    <CRow class="bodyItem">
      <CCol col="8">
        <CCol col="12" class="bodyItemValue y-center-g-5">
          <img
            :src="specialistImage"
            @error="() => (specialistImage = 'https://picsum.photos/200')"
            class="specialistImage"
            alt="Specialist Image"
          />
          <h4>{{ specialist.fullName }}</h4>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>شماره همراه :</span>
          <span>{{ specialist.phoneNumber }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>سابقه کار :</span>
          <span>{{ specialist.experience }} سال</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>توضیحات :</span>
          <span>{{ specialist.description }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>وضعیت :</span>
          <span :class="getStatusClass(specialist.status)">
            {{ getStatusLabel(specialist.status) }}
          </span>
        </CCol>
      </CCol>
      <CCol col="4 p-0">
        <!-- View Resume Button -->
        <CButton
          class="specialistItemBtn btn-view"
          @click="viewResume(specialist)"
        >
          <i class="fa fa-file" aria-hidden="true"></i>
          <span>دانلود رزومه</span>
        </CButton>
        <CButton
          color="secondary"
          class="specialistItemBtn"
          @click="highlightOnMap(specialist)"
        >
          <i class="fa fa-map-marker" aria-hidden="true"></i>
          <span>نمایش در نقشه</span>
        </CButton>
        <CButton
          color="secondary"
          class="specialistItemBtn"
          @click="highlightOnMap(specialist)"
        >
          <span>درخواست همکاری</span>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "SpecialistCard",
  props: {
    specialist: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      specialistImage: "https://picsum.photos/200", // Placeholder image
    };
  },
  methods: {
    getStatusClass(status) {
      return status === 1 ? "text-success" : "text-warning";
    },
    getStatusLabel(status) {
      return status === 1 ? "تایید شده" : "در حال بررسی";
    },
    viewResume(specialist) {
      // Logic to download resume
      alert(`دانلود رزومه برای ${specialist.fullName}`);
    },
    viewDetails(specialist) {
      // Logic to view specialist details
      this.$emit("viewDetails", specialist);
    },
    highlightOnMap(specialist) {
      // Logic to highlight specialist location on map
      this.$emit("highlightOnMap", specialist);
    },
  },
};
</script>

<style scoped>
.bodyItemValue {
  width: 100%;
}

.bodyItemValue h4 {
  font-size: 1rem;
  padding-right: 4px;
}

.specialistImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.btn-view {
  background-color: var(--primary-light);
  color: #fff;
}

.specialistItemBtn {
  width: 100%;
  margin-top: 4px;
  padding: 6px 0px;
}
</style>
