<template>
  <div class="tab-body-card">
    <!-- Filters Section -->
    <CRow class="mb-4 gap-3" align-vertical="end">
      <!-- From Date -->
      <CCol md="2">
        <label>از تاریخ</label>
        <date-picker
          placeholder="درخواست از تاریخ ..."
          v-model="fromDate"
          format="jYYYY/jMM/jDD"
          @change="onFromDateChange"
          :clearable="true"
        />
      </CCol>

      <!-- To Date -->
      <CCol md="2">
        <label>تا تاریخ</label>
        <date-picker
          v-model="toDate"
          placeholder="... درخواست تا تاریخ"
          format="jYYYY/jMM/jDD"
          @change="onToDateChange"
          :clearable="true"
        />
      </CCol>

      <!-- Status Filter -->
      <CCol md="3">
        <label>وضعیت</label>
        <vSelect
          dir="rtl"
          v-model="selectedStatus"
          :options="statusOptions" 
          placeholder="انتخاب وضعیت..."
          label="label"
          track-by="value"
        ></vSelect>
      </CCol>
    </CRow>

    <!-- No Data -->
    <no-data
      v-if="requestList.length === 0"
      :show="true"
      message="هیچ درخواستی یافت نشد"
      class="emptyTitle"
    />

    <!-- Display Requests as Cards -->
    <div v-else>
      <CRow>
        <CCol
          md="4"
          class="my-4"
          v-for="(request, index) in requestList"
          :key="index"
        >
          <RequestCard
            :request="request"
            @openAdvice="openAdviceModal"
            @openFarm="openFarmModal"
          />
        </CCol>
      </CRow>
    </div>

    <!-- Advice Modal -->
    <VueModal
      :title="'توصیه های ' + currentRequest.fullName"
      v-model="adviceModalState"
      wrapper-class="animate__animated animate__faster"
      modal-class="fullscreen-modal"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
    >
      <CRow>
        <CCol
          md="4"
          class="mb-4"
          v-for="(advice, index) in currentRequestAdvice"
          :key="index"
        >
          <MainFarmCard
            :title="advice.farmName"
            :fields="getAdviceFields(advice)"
          >
            <!-- Operations for Advice inside Advice Modal -->
            <template #actions>
              <CButton
                class="btn-View flex-grow-1"
                color="primary"
                @click="viewAdviceDetails(advice)"
              >
                <i class="fa fa-eye"></i> جزئیات
              </CButton>
            </template>
          </MainFarmCard>
        </CCol>
      </CRow>
    </VueModal>

    <!-- Farm Modal -->
    <VueModal
      :title="'زمین های ' + currentRequest.fullName"
      v-model="farmModalState"
      wrapper-class="animate__animated animate__faster"
      modal-class="fullscreen-modal"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
    >
      <CRow class="gap-10">
        <CCol md="4" v-for="(farm, index) in currentRequestFarms" :key="index">
          <MainFarmCard
            :imageSrc="getFarmImage(farm)"
            :title="farm.title"
            :fields="getFarmFields(farm)"
            :address="farm.address"
          >
            <!-- Operations for FarmItem inside Farm Modal -->
            <template #actions>
              <CButton
                class="btn-View flex-grow-1"
                color="primary"
                @click="viewFarmDetails(farm)"
              >
                <i class="fa fa-eye"></i> جزئیات
              </CButton>
            </template>
          </MainFarmCard>
        </CCol>
      </CRow>
    </VueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NoData from "../../../components/no-data.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import RequestCard from "./RequestCard.vue";
import { apiUrlRoot } from "../../../constants/config";
import MainFarmCard from "../../../components/MainFarmCard.vue"; // Ensure this path is correct
export default {
  name: "RequestHistoryTab",
  components: {
    RequestCard,
    NoData,
    vSelect,
    MainFarmCard,
  },
  data() {
    return {
      searchQuery: "",
      fromDate: "",
      toDate: "",
      selectedStatus: null,
      statusOptions: [
        { value: "", label: "همه" },
        { value: 0, label: "در حال بررسی" },
        { value: 1, label: "تایید شده" },
        { value: 2, label: "رد شده" },
      ],
      requestList: [],
      adviceModalState: false,
      farmModalState: false,
      currentRequest: {},
      currentRequestFarms: [], // This will hold the farms related to the current request
      currentRequestAdvice: [], // This will hold the advice data for the current request
    };
  },
  watch: {
    selectedStatus: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("farmerColleagues", ["GetAll"]),

    async fetchData() {
      this.requestList = [];
      let result = await this.GetAll({
        state: this.selectedStatus?.value ?? null,
        fullName: this.searchQuery,
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      if (result.succeeded) {
        console.log("result.datass", result.data);
        this.requestList = result.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
      this.requestList = result.data;
    },

    getFarmImage(farm) {
      return farm.productImage
        ? `${apiUrlRoot}ProductImage/${farm.productImage}`
        : "https://picsum.photos/200"; // Fallback image
    },
    // Method to get farm fields
    getFarmFields(farm) {
      return [
        { label: "نوع محصول", value: farm.product },
        { label: "مساحت", value: `${farm.farmArea} هکتار` },
        { label: "نوع کشت", value: farm.cultivationsTypeTitle },
        { label: "تاریخ کاشت", value: farm.cultivationsDate },
      ];
    },
    // Placeholder method for viewing farm details
    viewFarmDetails(farm) {
      // Handle viewing detailed information about the farm
      alert(`Viewing details for farm: ${farm.title}`);
    },
    // Get fields for advice data
    getAdviceFields(advice) {
      return [
        { label: "متن توصیه", value: advice.adviceText },
        { label: "تاریخ ایجاد", value: advice.createdAt },
      ];
    },
    // Placeholder method for viewing advice details
    viewAdviceDetails(advice) {
      alert(`Viewing advice details for farm: ${advice.farmName}`);
    },
    onFromDateChange() {
      this.fetchData();
    },
    onToDateChange() {
      this.fetchData();
    },
    onStatusChange() {
      this.fetchData();
    },
    openAdviceModal(request) {
      this.currentRequest = request;
      // Fetch related advice data here
      this.currentRequestAdvice = [
        {
          farmName: "تست آقای شاکری",
          adviceText: "بهتر است آبیاری منظم انجام شود و کوددهی بیشتر شود.",
          createdAt: "1403/05/26",
        },
        {
          farmName: "تست کنار دفتر",
          adviceText:
            "زمان برداشت نزدیک است، توصیه می‌شود اقدامات برداشت انجام شود.",
          createdAt: "1402/10/23",
        },
        {
          farmName: "فضل الله",
          adviceText: "خاک‌ورزی باید بهبود یابد تا محصول بیشتری برداشت شود.",
          createdAt: "1402/08/10",
        },
      ];
      this.adviceModalState = true;
    },
    openFarmModal(request) {
      this.currentRequest = request;
      // Fetch related farms here, for example:
      this.currentRequestFarms = [
        {
          farmId: 831,
          title: "تست آقای شاکری",
          product: "زراعی (گندم)",
          address: "خراسان رضوی / فریمان / اسلام آباد / مرکزی",
          farmArea: 0.000016,
          cultivationsTypeTitle: "آبی",
          cultivationsDate: "1403/05/26",
          productImage: "79bb39b7-cda5-4c28-ab09-94822ec1f757.png",
        },
        {
          farmId: 812,
          title: "تست کنار دفتر",
          product: "زراعی (گندم)",
          address: "خراسان رضوی / مشهد / صحرا / مرکزی",
          farmArea: 0.000295,
          cultivationsTypeTitle: "دیم",
          cultivationsDate: "1402/10/23",
          productImage: "79bb39b7-cda5-4c28-ab09-94822ec1f757.png",
        },
      ];
      this.farmModalState = true;
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.emptyTitle {
  text-align: center;
  font-size: 1.2rem;
  color: var(--secondary);
}

.fullscreen-modal {
  width: 90%;
  max-width: 90%;
  height: 95dvh;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
}
</style>
